// App.tsx
import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Provider as PaperProvider } from "react-native-paper";
import HomeScreen from "./screens/HomeScreen";
import MeasureScreen from "./screens/MeasureScreen";
import ReferenceScreen from "./screens/ReferenceScreen";

const Stack = createStackNavigator();

export default function App() {
  return (
    <PaperProvider>
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerStyle: {
              backgroundColor: "#A0C4FF",
            },
            headerTintColor: "#4B0082",
            headerTitleStyle: {
              fontWeight: "bold",
              fontFamily: "Poppins_700Bold",
              fontSize: 20,
            },
            headerTitleAlign: "center",
          }}
        >
          <Stack.Screen
            name="Home"
            component={HomeScreen}
            options={{ title: "Medidor de Anéis" }}
          />
          <Stack.Screen
            name="Medidas"
            component={MeasureScreen}
            options={{ title: "Medidas" }}
          />
          <Stack.Screen
            name="Referências"
            component={ReferenceScreen}
            options={{ title: "Referências" }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}
