// src/components/RingSizeTable.tsx
import React from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";

const ringSizeData = [
  { size: "10", circumference: "4,7 cm", diameter: (4.7 / Math.PI).toFixed(2) },
  { size: "11", circumference: "4,8 cm", diameter: (4.8 / Math.PI).toFixed(2) },
  { size: "12", circumference: "4,9 cm", diameter: (4.9 / Math.PI).toFixed(2) },
  { size: "13", circumference: "5,0 cm", diameter: (5.0 / Math.PI).toFixed(2) },
  { size: "14", circumference: "5,1 cm", diameter: (5.1 / Math.PI).toFixed(2) },
  { size: "15", circumference: "5,2 cm", diameter: (5.2 / Math.PI).toFixed(2) },
  { size: "16", circumference: "5,4 cm", diameter: (5.4 / Math.PI).toFixed(2) },
  { size: "17", circumference: "5,5 cm", diameter: (5.5 / Math.PI).toFixed(2) },
  { size: "18", circumference: "5,6 cm", diameter: (5.6 / Math.PI).toFixed(2) },
  { size: "19", circumference: "5,7 cm", diameter: (5.7 / Math.PI).toFixed(2) },
  { size: "20", circumference: "5,8 cm", diameter: (5.8 / Math.PI).toFixed(2) },
  { size: "21", circumference: "5,9 cm", diameter: (5.9 / Math.PI).toFixed(2) },
  { size: "22", circumference: "6,0 cm", diameter: (6.0 / Math.PI).toFixed(2) },
  { size: "23", circumference: "6,1 cm", diameter: (6.1 / Math.PI).toFixed(2) },
  { size: "24", circumference: "6,2 cm", diameter: (6.2 / Math.PI).toFixed(2) },
  { size: "25", circumference: "6,3 cm", diameter: (6.3 / Math.PI).toFixed(2) },
  { size: "26", circumference: "6,4 cm", diameter: (6.4 / Math.PI).toFixed(2) },
  { size: "27", circumference: "6,5 cm", diameter: (6.5 / Math.PI).toFixed(2) },
  { size: "28", circumference: "6,7 cm", diameter: (6.7 / Math.PI).toFixed(2) },
  { size: "29", circumference: "6,8 cm", diameter: (6.8 / Math.PI).toFixed(2) },
  { size: "30", circumference: "7,0 cm", diameter: (7.0 / Math.PI).toFixed(2) },
];

export default function RingSizeTable() {
  const renderItem = ({ item }) => (
    <View style={styles.row}>
      <Text style={styles.cell}>{item.size}</Text>
      <Text style={styles.cell}>{item.circumference}</Text>
      <Text style={styles.cell}>{item.diameter} cm</Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Tabela de Tamanhos de Anéis</Text>
      <View style={styles.headerRow}>
        <Text style={styles.headerCell}>Tamanho</Text>
        <Text style={styles.headerCell}>Circunferência</Text>
        <Text style={styles.headerCell}>Diâmetro</Text>
      </View>
      <FlatList
        data={ringSizeData}
        renderItem={renderItem}
        keyExtractor={(item) => item.size}
        contentContainerStyle={styles.list}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "#E0BBE4",
    borderRadius: 10,
    marginVertical: 20,
    width: "100%",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#4B0082",
    textAlign: "center",
    marginBottom: 15,
    fontFamily: "Poppins_700Bold",
  },
  headerRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#4B0082",
    paddingBottom: 5,
    marginBottom: 5,
  },
  headerCell: {
    flex: 1,
    fontSize: 16,
    fontWeight: "bold",
    color: "#4B0082",
    textAlign: "center",
    fontFamily: "Poppins_700Bold",
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingVertical: 10,
  },
  cell: {
    flex: 1,
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    fontFamily: "Poppins_400Regular",
  },
  list: {
    paddingBottom: 10,
  },
});
