import React, { useState } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  StyleSheet,
  PixelRatio,
} from "react-native";

const ringSizeCategories: any = [
  {
    id: "small",
    name: "Pequeno",
    sizes: [
      { id: "8", size: "8", diameter: (4.5 / Math.PI).toFixed(2) },
      { id: "9", size: "9", diameter: (4.6 / Math.PI).toFixed(2) },
      { id: "10", size: "10", diameter: (4.7 / Math.PI).toFixed(2) },
      { id: "11", size: "11", diameter: (4.8 / Math.PI).toFixed(2) },
      { id: "12", size: "12", diameter: (4.9 / Math.PI).toFixed(2) },
      { id: "13", size: "13", diameter: (5.0 / Math.PI).toFixed(2) },
      { id: "14", size: "14", diameter: (5.1 / Math.PI).toFixed(2) },
      { id: "15", size: "15", diameter: (5.2 / Math.PI).toFixed(2) },
    ],
  },
  {
    id: "medium",
    name: "Médio",
    sizes: [
      { id: "16", size: "16", diameter: (5.4 / Math.PI).toFixed(2) },
      { id: "17", size: "17", diameter: (5.5 / Math.PI).toFixed(2) },
      { id: "18", size: "18", diameter: (5.6 / Math.PI).toFixed(2) },
      { id: "19", size: "19", diameter: (5.7 / Math.PI).toFixed(2) },
      { id: "20", size: "20", diameter: (5.8 / Math.PI).toFixed(2) },
      { id: "21", size: "21", diameter: (5.9 / Math.PI).toFixed(2) },
    ],
  },
  {
    id: "large",
    name: "Grande",
    sizes: [
      { id: "22", size: "22", diameter: (6.0 / Math.PI).toFixed(2) },
      { id: "23", size: "23", diameter: (6.1 / Math.PI).toFixed(2) },
      { id: "24", size: "24", diameter: (6.2 / Math.PI).toFixed(2) },
      { id: "25", size: "25", diameter: (6.3 / Math.PI).toFixed(2) },
      { id: "26", size: "26", diameter: (6.4 / Math.PI).toFixed(2) },
      { id: "27", size: "27", diameter: (6.5 / Math.PI).toFixed(2) },
      { id: "28", size: "28", diameter: (6.6 / Math.PI).toFixed(2) },
      { id: "29", size: "29", diameter: (6.7 / Math.PI).toFixed(2) },
      { id: "30", size: "30", diameter: (6.8 / Math.PI).toFixed(2) },
      { id: "31", size: "31", diameter: (7.0 / Math.PI).toFixed(2) },
    ],
  },
];
const cmToPixels = (cm) => {
  const ppi = PixelRatio.get() * 160; // PPI do dispositivo
  return cm * (ppi / 2.54);
};

export default function MeasureScreen() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isStringMeasurement, setIsStringMeasurement] = useState(false);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSize(null);
    setIsStringMeasurement(false);
  };

  const renderCategoryItem = ({ item }) => (
    <TouchableOpacity
      style={styles.item}
      onPress={() => handleCategorySelect(item)}
    >
      <Text style={styles.text}>{item.name}</Text>
    </TouchableOpacity>
  );

  const renderSizeItem = ({ item }) => (
    <TouchableOpacity style={styles.item} onPress={() => setSelectedSize(item)}>
      <Text style={styles.text}>
        Tamanho: {item.size} (Diâmetro: {item.diameter} cm)
      </Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Escolha a categoria de tamanho do anel:</Text>
      <FlatList
        data={ringSizeCategories}
        renderItem={renderCategoryItem}
        keyExtractor={(item) => item.id}
        horizontal
        contentContainerStyle={styles.categoryContainer}
      />

      {selectedCategory && (
        <>
          <Text style={styles.subtitle}>
            {`Tamanhos na categoria ${selectedCategory.name}:`}
          </Text>
          <FlatList
            data={selectedCategory.sizes}
            renderItem={renderSizeItem}
            keyExtractor={(item) => item.id}
          />

          <TouchableOpacity
            style={styles.button}
            onPress={() => setIsStringMeasurement(!isStringMeasurement)}
          >
            <Text style={styles.buttonText}>
              {isStringMeasurement
                ? "Visualizar Medição Circular"
                : "Visualizar Medição com Barbante/Fita"}
            </Text>
          </TouchableOpacity>
        </>
      )}

      {selectedSize && (
        <View style={styles.resultContainer}>
          <Text style={styles.resultText}>
            Tamanho selecionado: Aro {selectedSize.size} |{" "}
            {isStringMeasurement
              ? `${(selectedSize.diameter * Math.PI).toFixed(2)} cm `
              : `${selectedSize.diameter} cm`}
          </Text>

          {isStringMeasurement ? (
            <View
              style={[
                styles.line,
                { width: cmToPixels(selectedSize.diameter * Math.PI * 0.5) },
              ]}
            />
          ) : (
            <View
              style={[
                styles.circle,
                {
                  width: cmToPixels(selectedSize.diameter / 2),
                  height: cmToPixels(selectedSize.diameter / 2),
                },
              ]}
            />
          )}

          <Text style={styles.infoText}>
            {isStringMeasurement
              ? "Coloque um barbante de comprimento igual a essa linha em volta do seu dedo e compare."
              : "Coloque seu dedo sobre o círculo para comparar o tamanho."}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    alignItems: "center",
    backgroundColor: "#fde9ff",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 20,
    color: "#4B0082",
    textAlign: "center",
    fontFamily: "Poppins_700Bold",
  },
  categoryContainer: {
    marginBottom: 20,
    justifyContent: "center",
  },
  subtitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#4B0082",
    marginBottom: 10,
    fontFamily: "Poppins_700Bold",
  },
  item: {
    paddingHorizontal: 15,
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderColor: "#A0C4FF",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: 18,
    color: "#4B0082",
    fontFamily: "Poppins_400Regular",
  },
  button: {
    marginVertical: 20,
    padding: 10,
    backgroundColor: "#A0C4FF",
    borderRadius: 30,
    borderColor: "#4B0082",
    borderWidth: 2,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  buttonText: {
    fontSize: 16,
    color: "#4B0082",
    textAlign: "center",
    fontFamily: "Poppins_700Bold",
  },
  resultContainer: {
    marginTop: 30,
    alignItems: "center",
  },
  resultText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#4B0082",
    marginBottom: 15,
    fontFamily: "Poppins_700Bold",
  },
  circle: {
    borderRadius: 1000,
    backgroundColor: "rgba(0, 150, 255, 0.1)",
    borderWidth: 1,
    borderColor: "#4B0082",
    marginBottom: 20,
  },
  line: {
    height: 2,
    backgroundColor: "#4B0082",
    marginBottom: 20,
  },
  infoText: {
    fontSize: 16,
    color: "#4B0082",
    textAlign: "center",
    fontFamily: "Poppins_400Regular",
  },
});
