import React from "react";
import { View, Text, ScrollView, Image, StyleSheet } from "react-native";
import RingSizeTable from "../components/RingSizeTable";

export default function ReferenceScreen() {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.title}>
        Tabela de Referências de Tamanho de Anéis
      </Text>
      <Text style={styles.subtitle}>Método do barbante ou fita:</Text>
      <Image
        source={require("../assets/medida-fita-3.jpg")}
        style={styles.image}
      />
      <Text style={styles.subtitle}>
        Use um barbante ou fita ao redor do dedo e compare com a régua para
        medir o tamanho.
      </Text>
      <Text style={styles.description}>
        <Text style={styles.subtitle}>1. </Text>
        Envolva seu dedo com uma fita ou barbante e marque o ponto de encontro
        das extremidades.{"\n"}
        <Text style={styles.subtitle}>2. </Text>
        Meça o comprimento do barbante com uma régua e compare com a tabela.
      </Text>
      <RingSizeTable />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 20,
    alignItems: "center",
    backgroundColor: "#fde9ff",
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#4B0082",
    marginBottom: 20,
    textAlign: "center",
    fontFamily: "Poppins_700Bold",
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#4B0082",
    marginTop: 20,
    fontFamily: "Poppins_700Bold",
  },
  description: {
    fontSize: 16,
    color: "#4B0082",
    marginVertical: 10,
    textAlign: "center",
    fontFamily: "Poppins_400Regular",
  },
  image: {
    width: 400,
    height: 200,
    marginVertical: 10,
    borderRadius: 10,
    resizeMode: "contain",
  },
});
