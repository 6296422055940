import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from "react-native";

export default function HomeScreen({ navigation }) {
  return (
    <View style={styles.background}>
      <View style={styles.overlay}>
        <Text style={styles.title}>Bem-vindo ao Medidor de Anéis</Text>
        <Text style={styles.subtitle}>
          Escolha o tamanho do anel para começar
        </Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate("Medidas")}
        >
          <Text style={styles.buttonText}>Conferir medidas</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.spacing]}
          onPress={() => navigation.navigate("Referências")}
        >
          <Text style={styles.buttonText}>Ver referências</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const { height } = Dimensions.get("window");

const styles = StyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: "#E0BBE4",
    justifyContent: "center",
    alignItems: "center",
    height: height,
  },
  overlay: {
    backgroundColor: "rgba(255, 223, 186, 0.8)",
    padding: 20,
    borderRadius: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#4B0082",
    marginBottom: 10,
    fontFamily: "Poppins",
  },
  subtitle: {
    fontSize: 16,
    color: "#4B0082",
    marginBottom: 20,
    fontFamily: "Poppins",
  },
  button: {
    backgroundColor: "#A0C4FF",
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 30,
    borderColor: "#4B0082",
    borderWidth: 2,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    marginBottom: 10,
  },
  spacing: {
    marginTop: 10,
  },
  buttonText: {
    color: "#4B0082",
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Poppins",
  },
});
